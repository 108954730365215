import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import styles from './ImageCropper.module.scss';
import ColoredText from '../ColoredText';

const getBoundingBoxImageData = (image, leftPct, topPct, widthPct, heightPct) => {
    const imgWidth = image.naturalWidth;
    const imgHeight = image.naturalHeight;

    const leftPx = Math.floor(imgWidth / 100 * leftPct);
    const topPx = Math.floor(imgHeight / 100 * topPct);

    const widthPx = Math.floor(imgWidth / 100 * widthPct);
    const heightPx = Math.floor(imgHeight / 100 * heightPct);


    const canvas = document.createElement('canvas');
    canvas.width = widthPx;
    canvas.height = heightPx;
    const context = canvas.getContext('2d');

    context.drawImage(image, -leftPx, -topPx);
    return {
        data: canvas.toDataURL('image/png'),
        width: widthPx,
        height: heightPx,
    };
};

const BoundingBoxCorner = ({ boxPoints, xPath, yPath, onMouseDown }) => {
    const x = lodash.get(boxPoints, xPath);
    const y = lodash.get(boxPoints, yPath);

    const isWest = x === Math.min(boxPoints.pointA.x, boxPoints.pointB.x);
    const isNorth = y === Math.min(boxPoints.pointA.y, boxPoints.pointB.y);

    const cursor = `${isNorth ? 'n' : 's'}${isWest ? 'w' : 'e'}-resize`;

    return (
        <div
            onMouseDown={e => {
                e.stopPropagation();
                onMouseDown(xPath, yPath);
            }}
            style={{
                position: 'absolute',
                width: '8px',
                height: '8px',
                background: 'white',
                left: `calc(${x}% - 4px)`,
                top: `calc(${y}% - 4px)`,
                outline: '1px solid black',
                cursor,
            }}
        />
    );
};

const ImageCropper = ({ image, onCrop }) => {
    const containerRef = useRef();
    const [mounted, setMounted] = useState(false); 
    const [dragging, setDragging] = useState(null);
    const [cornerPointsBeingMoved, setCornerPointsBeingMoved] = useState([]);
    // coordinations are stored as percentages
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    const [boxPoints, setBoxPoints] = useState({
        pointA: { x: 25, y: 25 },
        pointB: { x: 75, y: 75 },
    });

    const { pointA, pointB } = boxPoints;

    const width = Math.abs(pointA.x - pointB.x);
    const height = Math.abs(pointA.y - pointB.y);

    const left = Math.min(pointA.x, pointB.x);
    const top = Math.min(pointA.y, pointB.y);

    const widthPx = Math.floor(image.naturalWidth / 100 * width);
    const heightPx = Math.floor(image.naturalHeight / 100 * height);

    const cropBoundingBox = (
        <div
            onMouseDown={() => setDragging(true)}
            className={styles.cropBox}
            style={{
                position: 'absolute',
                left: `${left}%`,
                top: `${top}%`,
                width: `${width}%`,
                height: `${height}%`,
                cursor: dragging ? 'grabbing' : 'grab',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            }}
        >
            <div
                style={{
                    background: 'black',
                    display: 'inline-block',
                    padding: '0em 0.25em',
                    borderBottomRightRadius: '0.5em', 
                    opacity: cornerPointsBeingMoved.length > 0 ? 0.75 : 0.25,
                }}
            >
                <ColoredText content={widthPx} color='white' />&nbsp;
                <ColoredText content='×' color='white' />&nbsp;
                <ColoredText content={heightPx} color={heightPx < 150 ? 'red' : 'white'} />
            </div>
        </div>
    );
    
    const renderBoundingBoxCorner = (xPath, yPath) => {
        return (
            <BoundingBoxCorner
                onMouseDown={onCropPointMouseDown}
                boxPoints={boxPoints}
                xPath={xPath}
                yPath={yPath}
            />
        );
    };

    useEffect(() => {
        if (mounted) {
            return;
        }

        setMounted( true);
        const cropData = getBoundingBoxImageData(image, left, top, width, height);
        onCrop(cropData);
    }, [mounted, image, onCrop, left, top, width, height]);

    useLayoutEffect(() => {
        const handleMouseMouse = e => {
            const box = containerRef.current?.getBoundingClientRect();
            if (!box) return;

            const xPos = e.clientX - box.x;
            const yPos = e.clientY - box.y;

            const clamp = (val, min, max) => {
                if (val > max) return max;
                if (val < min) return min;
                return val;
            }
    
            const x = clamp(Math.max(xPos / box.width * 100, 0), 0, 100);
            const y = clamp(Math.max(yPos / box.height * 100, 0), 0, 100);
    
            setMousePos({ x, y });

            if (cornerPointsBeingMoved.length > 0) {
                const points = { ...boxPoints };

                const [xPath, yPath] = cornerPointsBeingMoved;

                lodash.set(points, xPath, x);
                lodash.set(points, yPath, y);

                setBoxPoints(points);
            }

            if (dragging) {
                const points = { ...boxPoints };

                const diffX = mousePos.x - x;
                const diffY = mousePos.y - y;

                points.pointA.x = clamp(points.pointA.x - diffX, 0, 100);
                points.pointB.x = clamp(points.pointB.x - diffX, 0, 100);
                points.pointA.y = clamp(points.pointA.y - diffY, 0, 100);
                points.pointB.y = clamp(points.pointB.y - diffY, 0, 100);

                setBoxPoints(points);
            }
        };

        const handleMouseUp = () => {
            setCornerPointsBeingMoved([]);
            setDragging(false);
            const cropData = getBoundingBoxImageData(image, left, top, width, height);
            onCrop(cropData);
        };

        window.addEventListener('mousemove', handleMouseMouse);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMouse);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [cornerPointsBeingMoved, boxPoints, dragging, mousePos, image, left, top, width, height, onCrop]);

    const onCropPointMouseDown = (xPath, yPath) => setCornerPointsBeingMoved([xPath, yPath]);

    return (
        <div
            className={styles.noselect}
            style={{ position: 'relative' }}
            ref={containerRef}
        >
            {cropBoundingBox}
            {renderBoundingBoxCorner('pointA.x', 'pointA.y')}
            {renderBoundingBoxCorner('pointB.x', 'pointB.y')}
            {renderBoundingBoxCorner('pointA.x', 'pointB.y')}
            {renderBoundingBoxCorner('pointB.x', 'pointA.y')}
            <img
                src={image.src}
                className={styles.noselect}
                style={{ outline: '1px solid lightgray', maxWidth: '100%', maxHeight: '500px', height: 'auto', display: 'block' }}
                alt='logo'
            />
        </div>
    );
};

export default ImageCropper;