import React from 'react';
import { Button, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import compose from 'lodash.flowright';
import withUserData from 'util/withUserData';
import variationLabels from 'util/variationLabels';
import * as tawkTo from 'util/tawkTo';
import PageHeader from 'design/atoms/PageHeader';
import ProductPage from 'design/molecules/ProductPage';
import { Media } from 'design/atoms/Media';

import ProductWrapper from './ProductWrapper';

class Product extends React.Component {
    componentDidMount = () => {
        const shouldReload = tawkTo.shouldReloadWidget(this.props.userData);
        if (shouldReload) {
            window.location.reload();
        }
    };

    buildTitle = () => {
        const { product, stripeProduct, testmode, modelId, label, title, icon } = this.props;

        if (title && icon) {
            return { title, icon };
        }

        if (testmode) {
            return {
                title: modelId,
                icon: 'code',
            };
        }

        const titleParts = [];

        const productKind = (
            label === variationLabels.conversion.id ?
            variationLabels.conversion.description :
            product.kind
        );

        if (productKind && product.year) {
            titleParts.push(`${productKind} ${product.year}`);
        }

        return {
            ready: true,
            title: <>{titleParts.join(' ∙ ')}</>,
            icon: stripeProduct.semanticIcon,
        };
    };

    buildPaymentLink = () => {
        const { location, paymentURL } = this.props;

        if (!paymentURL) {
            return '';
        }

        const currentPage = encodeURIComponent(location.pathname);
        return `${paymentURL}?redirect=${currentPage}`;
    };

    renderPurchaseButton = () => {
        const { hasPayed, paymentURL, alwaysShowPaymentButton } = this.props;

        if (hasPayed && !alwaysShowPaymentButton) {
            return undefined;
        }

        if (!paymentURL) {
            return undefined;
        }
        
        const text = 'Få fuld adgang';
        const icon = 'unlock';

        return (
            <Link to={this.buildPaymentLink()}>
                <Media gte='tablet'>
                    <Button
                        primary
                        size='tiny'
                        style={{ padding: '0.35em 0.65em', margin: '-1px' }}
                        children={
                            <span>
                                {text}
                                &nbsp;
                                <Icon name={icon} />
                            </span>
                        }
                    />
                </Media>
                <Media lt='tablet'>
                    <Popup
                        content={text}
                        position='left center'
                        trigger={
                            <Icon
                                name={icon}
                                size='large'
                                color='green'
                                link
                            />
                        }
                    />
                </Media>
            </Link>
        );
    };

    render () {
        const { error, loading } = this.props;

        if (error) {
            return (
                <ProductPage>
                    <Message
                        error
                        content='Der opstod en fejl'
                        icon='warning circle'
                    />
                </ProductPage>
            );
        }

        if (loading) {
            return (
                <ProductPage subHeader={<PageHeader loading />}>
                    <Loader inline='centered' active size='huge' />
                </ProductPage>
            );
        }

        const { title, icon } = this.buildTitle();

        const subHeader = {
            icon,
            title,
            link: this.props.landingLocation,
            rightContent: this.renderPurchaseButton(),
        };

        return (
            <ProductWrapper
                product={this.props.modelId}
                year={this.props.year}
                label={this.props.label}
                {...this.props.match.params}
                pagePrefix={this.props.pagePrefix}
                landingLocation={this.props.landingLocation}
                history={this.props.history}
                location={this.props.location}
                hasPayed={this.props.hasPayed}
                paymentURL={this.props.paymentURL}
                paymentURLWithRedirect={this.buildPaymentLink()}
                isLocked={this.props.isLocked}
                subHeader={subHeader}
                productMetadata={this.props.product}
                isTeaser={this.props.isTeaser}
            />
        );
    }
}

Product.defaultProps = {
    label: variationLabels.regular.id,
};

export default compose(withUserData)(Product);
