/** 
 * @returns {string}
 */
const readConfigValue = (key, def = '') => {
    const envConfigLookup = window.__env ? window.__env[key] : undefined;
    if (envConfigLookup !== undefined) {
        return envConfigLookup;
    }

    const localEnvLookup = import.meta.env[`VITE_${key}`];
    if (localEnvLookup !== undefined) {
        return localEnvLookup;
    }

    return def;
};

const readConfigFlag = (key, defaultFlagValue = false) => {
    return readConfigValue(key, defaultFlagValue.toString()) === 'true';
};

export const ADMIN_LOCATION = readConfigValue('ADMIN_LOCATION', 'http://localhost:3000');
export const API_LOCATION = readConfigValue('API_LOCATION', 'http://localhost:4000/');
export const STRIPE_KEY = readConfigValue('STRIPE_KEY', 'pk_test_U4vtxhi2X3pwki2y7ZPWatMd');
export const FREE_TAWKTO_ID = readConfigValue('FREE_TAWKTO_ID', '5a2fb4cbd0795768aaf8ec4a/default');
export const SUPPORT_TAWKTO_ID = readConfigValue('SUPPORT_TAWKTO_ID', '5acf454e4b401e45400e9171/default');
export const BUSINESS_TAWKTO_ID = readConfigValue('BUSINESS_TAWKTO_ID', '5acf454e4b401e45400e9171/default');
export const ACCOUNTANT_TAWKTO_ID = readConfigValue('ACCOUNTANT_TAWKTO_ID', '6458dbd1ad80445890ebb6ac/1gvthlaqh');
export const AKADEMI_TAWKTO_ID = readConfigValue('AKADEMI_TAWKTO_ID', '63bc1f5e47425128790c7788/1gmbdl2mn');
export const ESG_TAWKTO_ID = readConfigValue('ESG_TAWKTO_ID', '674d6e0c2480f5b4f5a69a9f/1ie37boh9');
export const ECONOMIC_AUTH_LOCATION = readConfigValue('ECONOMIC_AUTH_LOCATION', 'https://app.digitalrevisor.nu/integrations/dev-local-economic-proxy?redirectUrl=http%3A%2F%2Flocalhost%3A8000%2Fintegrations%2Fe-conomic');
export const ACCOUNTANT_HELP_CALENDLY_BOOKING_URL = readConfigValue('ACCOUNTANT_HELP_CALENDLY_BOOKING_URL', 'https://calendly.com/booking-opstartshjaelp/opstartshjaelp-test');
export const APP_TITLE = readConfigValue('APP_TITLE', 'App ∙ Local');
export const HOTJAR_ID = readConfigValue('HOTJAR_ID', '');
export const HOTJAR_SV = readConfigValue('HOTJAR_SV', '');
export const ACCOUNTANT_COURSE_MODEL_ID = readConfigValue('ACCOUNTANT_COURSE_MODEL_ID', 'RAADGIVERKURSUS');
export const ACCOUNTANT_COURSE_PRODUCT_ID = readConfigValue('ACCOUNTANT_COURSE_PRODUCT_ID', 'accountantCourse');
export const RECAPTCHA_SITE_KEY = readConfigValue('RECAPTCHA_SITE_KEY', '');

export const IS_PRODUCTION = readConfigFlag('IS_PRODUCTION', false);
export const FBTRACK = readConfigFlag('FBTRACK', false);
export const TRACKING_LOGGING = readConfigFlag('TRACKING_LOGGING', false);
