import React from 'react';
import get from 'lodash.get';
import {
    Icon,
    Container,
    Table,
    Popup,
    Segment,
    Input,
    Grid,
    Message,
    Loader,
    Label,
    Dropdown,
    Header,
    Flag,
    Form,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import i18n from 'i18n/pages/AccountantOverview';
import { getClientInfo } from 'http/productEngine';
import getProducts from 'util/getProducts';
import variationLabels from 'util/variationLabels';
import erpSystems, { erpIdentifiers } from 'util/erpSystems'
import { beginImpersonation } from 'util/impersonation';
import { isPrivat, isErhverv, isAdmin } from 'util/userMethods';
import { withRootUserData } from 'util/withUserData';
import { withPersistedState } from 'util/usePersistedState';
import { getFlagCode } from 'lang/countries';
import ColoredText from 'design/atoms/ColoredText';
import ErrorDisplayer from 'design/atoms/ErrorDisplayer';
import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';
import AccountSuspendedMessage from 'design/molecules/AccountSuspendedMessage';
import CreateClientModal from 'design/molecules/CreateClientModal';
import Tooltip from 'design/atoms/Tooltip';

import ClientSettings from './ClientSettings';

import styles from './styles.module.scss';

const ICON_PRIVATE = 'user';
const ICON_BUSINESS = 'building';

const ClientDropdownItem = ({ key, text, tooltip, icon, onClick }) => {
    return (
        <Dropdown.Item
            key={key}
            onClick={onClick}
            content={
                <div className={styles.clientDropdownItem}>
                    <div className={styles.clientDropdownElement}>
                        <Icon name={icon} />
                    </div>
                    <div className={styles.clientDropdownElement}>
                        {text} <br />
                        <ColoredText
                            content={tooltip}
                            color='grey'
                            italic
                        />
                    </div>
                </div>
            }
        />
    );
};

class AccountantOverview extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            clientsInfo: {},
            products: {},
            loading: true,
            error: false,
            working: false,
            errorMsg: '',
            filterQuery: '',
            activeModal: null,
            akademiDefault: false,
        };
        this.ACCOUNTANT_ERP_COLUMN = isAdmin(props.userData);
        this.CLIENT_TYPES = [
            {
                type: 'COMPANY',
                text: 'Selskab',
                header: 'Opret selskab',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: i18n.firstClientTeaserCompany,
            },
            {
                type: 'FIRM',
                text: 'Virksomhed',
                header: 'Opret virksomhed',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: i18n.firstClientTeaserFirm,
            },
            {
                type: 'IS',
                text: 'Interessentskab',
                header: 'Opret interessentskab',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: 'Personligt ejet I/S',
            },
            {
                type: 'GREENLAND',
                text: 'Grønlandsk selskab/virksomhed',
                header: 'Opret grønlandsk selskab/virksomhed',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: 'Firmaer med grønlandsk postnr.',
            },
            {
                type: 'PRIVATE',
                text: 'Boligudlejer',
                header: 'Opret boligudlejer',
                isPrivate: true,
                icon: ICON_PRIVATE,
                tooltipText: i18n.firstClientTeaserPrivat,
            },
        ];
    }

    fetchRequiredData = async () => {
        const [clientsInfo, products] = await Promise.all([
            getClientInfo(),
            getProducts(),
        ]);
        this.setState({
            clientsInfo,
            products,
        });
    };

    componentDidMount = () => this.loadData();

    loadData = async () => {
        try {
            this.setState({
                loading: true,
            });
            await this.fetchRequiredData();
            this.setState({
                loading: false,
            });
        } catch (e) {
            this.setState({
                loading: false,
                error: true,
            });
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.userData?.id !== prevProps.userData?.id) {
            this.loadData();
        }
    };

    refetchClientList = async () => {
        this.setState({ working: true });
        await this.fetchRequiredData();
        this.setState({ working: false });
    };

    formatClientsData = () => {
        const { clientsInfo } = this.state;

        return Object.keys(clientsInfo.clientStatuses).map(clientId => {
            const clientInfo = clientsInfo.clientDetails[clientId];
            const activeVariations = clientsInfo.clientStatuses[clientId];
            return { clientId, activeVariations, ...clientInfo };
        });
    };

    hasClients = () => {
        const { clientsInfo } = this.state;
        return Object.keys(clientsInfo.clientStatuses) > 0;
    };

    serviceID2productID = serviceID => {
        const product = this.state.products.find(p => p.serviceID === serviceID);
        if (product) {
            return product.id;
        }
        return serviceID;
    };

    startImpersonation = async (cid, href) => {
        this.setState({ working: true });
        try {
            await beginImpersonation(cid);
            this.props.history.push(href);
        } catch (e) {
            this.setState({
                errorMsg: i18n.startImpersonationError,
                working: false,
            });
        }
    };

    gotoCustomerFrontpage(cid) {
        this.startImpersonation(cid);
    }

    onClick (cid, serviceId) {
        this.startImpersonation(cid, `/service/${serviceId}`);
    }

    impAndGotoProduct = (cid, pid, serviceType, year, label) => {
        const url = `/service/${pid}/${serviceType}/${year}/${label}/index`;
        this.startImpersonation(cid, url);
    };

    countClients = () => {
        const { clientsInfo } = this.state;
        const clients = get(clientsInfo, 'clientDetails', {});
        return Object.keys(clients).length;
    };

    onClientDeleted = async () => {
        this.setState({ pickedClient: null, working: true });
        await this.loadData();
        this.setState({ working: false });
    };

    // Add a wrapper with a key-property. Because React.
    addKey(elm, key) {
        return <React.Fragment key={key}>{elm}</React.Fragment>;
    }

    getPickedStatuses = () => {
        return this.props.filterClientsForm?.value?.pickedStatuses || [];
    };

    getPickedTaxYears = () => {
        return this.props.filterClientsForm?.value?.pickedTaxYears || [];
    };

    onlyDisplayAssignedClients = () => {
        const { userData } = this.props;

        if(userData.isLoginAkademi() && !this.state.akademiDefault){
            return true;
        }

        return this.props.filterClientsForm?.value?.onlyDisplayAssignedClients || false;
    };

    getClientVariations = client => {
        const { clientsInfo } = this.state;

        const variations = [];

        for (const variation of client.activeVariations) {
            const productInfo = clientsInfo.serviceVariations[variation.productId];
            if (!productInfo) {
                continue;
            }

            // HACK: correct statuses on boligudlejning variations
            // "done" => "reported"
            const isBoligudlejning = productInfo.serviceId === 'boligudlejning';
            if (isBoligudlejning && variation.status === statusesMap.done.id) {
                variation.status = statusesMap.reported.id;
            }
            
            const statusInfo = statusesMap[variation.status];
            if (!statusInfo) {
                continue;
            }

            const { step } = statusInfo;
            
            variations.push({
                ...variation,
                ...productInfo,
                step,
            });
        }

        return variations;
    };

    shouldRenderClient = client => {
        const { filterQuery } = this.state;

        if (filterQuery) {
            if (!client.displayName.toLowerCase().includes(filterQuery.toLowerCase())) {
                return false;
            }
        }

        const pickedStatuses = this.getPickedStatuses();
        const pickedTaxYears = this.getPickedTaxYears();
        if (pickedStatuses?.length > 0 || pickedTaxYears?.length > 0) {
            if (this.getClientVisibleVariations(client).length === 0) {
                return false;
            }
        }

        const onlyDisplayAssignedClients = this.onlyDisplayAssignedClients();
        if (onlyDisplayAssignedClients) {
            if (!client.isAssignedToLoggedInUser) {
                return false;
            }
        }

        return true;
    };

    renderHeaderContent = () => {
        const { userData } = this.props;

        // render "first time logged in" message, if no clients yet
        if (this.countClients() === 0) {
            return <p>
                {i18n.welcomeMessage}<br />
                {i18n.enjoyDigitalRevisor}
            </p>;
        }

        // else render display name
        return <span>{userData.displayName}</span>;
    };

    renderNoConnectedClientsSegment = () => {
        const user = this.props.userData;

        let content;
        if (user.isEntityAccountant()) {
            content = (
                <>
                    <Container textAlign='center'>
                        <h3>
                            <Icon size='big' name='user plus' />
                            <br/>
                            {i18n.createYourFirstClient}
                        </h3>
                    </Container>
                    <br />
                    {this.renderCreateClientButtons()}
                </>
            );
        } else {
            content = (
                <div style={{ textAlign: 'center' }}>
                    <h3>Der er ingen klienter at vise...</h3>
                </div>
            );
        }

        return (
            <Segment placeholder className={styles.noClientsBox}>
                {content}
            </Segment>
        );
    };

    renderCreateClientButtons = () => {
        const user = this.props.userData;

        if (!user.isEntityAccountant()) {
            return null;
        }

        return (
            <Dropdown
                className='icon primary right labeled'
                text='Opret klient'
                icon='caret down'
                pointing='top'
                labeled
                button
            >
                <Dropdown.Menu>
                    <Dropdown.Header content='Vælg klienttype' />
                    <Dropdown.Divider />
                    {
                        this.CLIENT_TYPES.map(({ type, text, icon, tooltipText }) => (
                            <ClientDropdownItem
                                key={type}
                                text={text}
                                tooltip={tooltipText}
                                icon={icon}
                                onClick={() => this.setState({ activeModal: type })}
                            />
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    renderFilterClientsForm = (clients) => {
        const activeStatusSteps = new Set();
        const activeTaxYears = new Set();

        clients.forEach(client => {
            this.getClientVariations(client).forEach(({ year, step }) => {
                activeStatusSteps.add(step);
                activeTaxYears.add(year);
            });
        });
        
        const statusOptions = [...activeStatusSteps].map(statusStep => {
            const statusInfo = Object.values(statusesMap).find(status => status.step === statusStep && !status.deprecated);
            
            return {
                text: statusInfo.displayText,
                icon: statusInfo.icon,
                value: statusStep,
            };
        }).sort((a, b) => a.value - b.value);

        const user = this.props.userData;

        const pickedStatuses = this.getPickedStatuses();
        const pickedTaxYears = this.getPickedTaxYears();
        const onlyDisplayAssignedClients = this.onlyDisplayAssignedClients();

        const anyClientsHaveColleguesAssigned = clients.some(client => {
            if (client.isAssignedToLoggedInUser) {
                return true;
            }

            return client.responsibleLoginIds?.length > 0;
        });

        return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Søg</label>
                        <Input 
                            icon='search'
                            placeholder={i18n.searchInClients}
                            onChange={(_, { value: filterQuery }) => this.setState({ filterQuery })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Status</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            multiple
                            placeholder='Filtrér på status...'
                            defaultValue={pickedStatuses}
                            onChange={(_, { value }) => this.props.filterClientsForm.setValue({
                                ...this.props.filterClientsForm.value,
                                pickedStatuses: value, 
                            })}
                            options={statusOptions}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Skatteår</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            multiple
                            placeholder='Filtrér på skatteår...'
                            defaultValue={pickedTaxYears}
                            onChange={(_, { value }) => this.props.filterClientsForm.setValue({
                                ...this.props.filterClientsForm.value,
                                pickedTaxYears: value, 
                            })}
                            options={[...activeTaxYears].sort().reverse().map(taxYear => ({
                                text: taxYear,
                                value: taxYear,
                            }))}
                        />
                    </Form.Field>
                    
                    {user.isEntityAccountant() && (anyClientsHaveColleguesAssigned || onlyDisplayAssignedClients) && (
                        <Form.Field>
                            <label>Visning</label>
                            <Dropdown
                                selection
                            onChange={(_, { value }) => {
                                this.props.filterClientsForm.setValue({
                                    ...this.props.filterClientsForm.value,
                                    onlyDisplayAssignedClients: value,
                                });
                                this.setState({ akademiDefault: true });
                            }}
                                defaultValue={onlyDisplayAssignedClients}
                                options={[
                                    {
                                        text: 'Vis alle klienter',
                                        value: false,
                                    },
                                    {
                                        text: 'Vis kun mine klienter',
                                        value: true,
                                    },
                                ]}
                            />
                        </Form.Field>
                    )}
                </Form.Group>
            </Form>
        );
    };

    renderTable = clients => {
        const { working, errorMsg } = this.state;
        if (clients.length === 0) {
            return this.renderNoConnectedClientsSegment();
        }

        // Bogføringssystem column
        let erpColumn;
        let amountOfColumns = 4;
        if (this.ACCOUNTANT_ERP_COLUMN) {
            erpColumn = <Table.HeaderCell collapsing>
                <Popup
                    trigger={<Icon name="book" />}
                    content="Klientens bogføringssystem"
                    />
            </Table.HeaderCell>;
            amountOfColumns++;
        }

        const clientsToShow = (
            clients
            .filter(this.shouldRenderClient)
            .reverse()
            .map(c => [this.renderTableRow(c), c.email])     // map to [<tr>, email]
            .map(([elm, email]) => this.addKey(elm, email))  // add unique keys for react
        );

        const clientsFooterLabel = (
            <Label>
                <Icon name='users' />
                Viser {clientsToShow.length} ud af {clients.length}{' '}
                klient{clients.length === 1 ? '' : 'er'}
            </Label>
        );

        return (
            <Container as={Segment} vertical disabled={working}>
                {this.renderFilterClientsForm(clients)}

                <Table compact='very' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" className={styles.hideIconOnSmallDevices}>
                            </Table.HeaderCell>
                            { erpColumn }
                            <Table.HeaderCell>{i18n.clientName}</Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup
                                wide
                                trigger={<span>{i18n.clientStatus}</span>}
                                content={<p>{i18n.progressInTaxYearInfo}</p>}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {clientsToShow}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={amountOfColumns} textAlign="right">
                                {clientsFooterLabel}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>
                { errorMsg && <ErrorDisplayer error={errorMsg}/> }
            </Container>
        );
    };


    // aktive produkter (Variations) for hver klient. Dvs. de ikke-fede linier for hver klient
    getClientVariationJSX = (variation, cid) => {
        const { clientsInfo } = this.state;
        const variationInfo = clientsInfo.serviceVariations[variation.productId];

        if (variationInfo) {
            const { serviceId, serviceType, year } = variationInfo;
            const productID = this.serviceID2productID(serviceId);
            const variationLink = (
                <Popup
                    content= {i18n.goToProductAsAClient}
                    position='right center'
                    trigger={
                        <Icon
                            link
                            color='green'
                            name='external'
                            onClick={() => this.impAndGotoProduct(cid, productID, serviceType, year, variation.label)}
                        />
                    }
                />
            );
            
            // build variation title
            let productLabel;
            if (variation.label === variationLabels.conversion.id) {
                productLabel = variationLabels.conversion.description;
            } else {
                productLabel = serviceType;
            }
            
            const variationStatus = this.renderStatusCol(serviceType, variation.status, variationLink);
            if (!variationStatus) {
                return {};
            }

            const variationTitle = <div>{productLabel} {year} {variationLink}</div>;
            
            return {
                title: variationTitle,
                status: variationStatus,
            }
        }
        return {};
    };

    getClientVisibleVariations = client => {
        const pickedStatuses = this.getPickedStatuses();
        const pickedTaxYears = this.getPickedTaxYears();

        return this.getClientVariations(client).filter(variation => {
            if (pickedStatuses.length > 0 && !pickedStatuses.includes(variation.step)) {
                return false;
            }

            if (pickedTaxYears.length > 0 && !pickedTaxYears.includes(variation.year)) {
                return false;
            }

            return true;
        });
    };
    
    renderTableRow = (client) => {
        const variationJSX = this.getClientVisibleVariations(client)
            .sort((a, b) => Number(b.year) - Number(a.year))
            .map(av => this.getClientVariationJSX(av, client.clientId));
        
        let icon;
        if (isPrivat(client)) {
            icon = ICON_PRIVATE;
        } else {
            icon = ICON_BUSINESS;
        }
        
        let erpColumn;
        if (this.ACCOUNTANT_ERP_COLUMN) {
            erpColumn = <Table.Cell collapsing className={`${styles.erpTableCell} ${styles.hideIconOnSmallDevices}`}>
                { this.renderERP(client.erp) }
            </Table.Cell>;
        }
        
        const flagCode = getFlagCode(client.rapportLanguage) || 'dk';
        const rapportLanguageFlag = isErhverv(client) ? <Popup basic size='mini' content='Klientens rapportsprog' trigger={<Flag name={flagCode} />} /> : null;
        
        const user = this.props.userData;

        const stakeholderCount = client.logins?.length || 0;
        const associatedLoginsIndicator = stakeholderCount > 0 && (
            <Tooltip
                data={
                    <div>
                        <b>Tilknyttede kollegaer:</b>
                        {client.logins.map(email => {
                            const youLabel = email === user.login.loginEmail ? ' (dig)' : '';

                            return <div>{email + youLabel}</div>;
                        })}
                    </div>
                }
                position='top center'
                triggerOverwrite={
                    <Icon name={stakeholderCount === 1 ? 'user' : 'users'} color='grey' />
                }
            />
        );

        const settingsIcon = user.isEntityAdmin() && (
            <Tooltip
                data='Indstillinger for klient'
                triggerOverwrite={
                    <Icon
                        name='cog'
                        link
                        onClick={() => this.setState({ pickedClient: client })}
                    />
                }
            />
        );

        return (
            <Table.Row>
                <Table.Cell className={styles.hideIconOnSmallDevices} collapsing>
                    { this.renderClientIcon(icon, client.clientId) }
                </Table.Cell>
                { erpColumn }
                <Table.Cell>
                    <strong className={styles.hoverPointer} onClick={() => this.gotoCustomerFrontpage(client.clientId)} >
                        { client.displayName }
                    </strong><br />
                    { variationJSX.map(v => v.title).map((elm, i) => this.addKey(elm, i)) }
                </Table.Cell>
                <Table.Cell>
                    <br />
                    { variationJSX.map(v => <>{v.status} {v.link}</>).map((elm, i) => this.addKey(elm, i)) }
                </Table.Cell>
                <Table.Cell textAlign="right">
                    {rapportLanguageFlag}
                    {associatedLoginsIndicator}
                    {settingsIcon}
                </Table.Cell>
            </Table.Row>
        );
    }

    renderERP = erp => {
        const erpSystem = erpSystems[erp];
        if (!erpSystem) {
            return null;
        }

        let logo = <img src={erpSystem.logo} alt={`${erp} logo`} data-erp={erp} className={styles.erpSmallLogo} />;
        if (erp === erpIdentifiers.manual) {
            logo = <Icon name="calculator" size="large" color='grey' data-erp={erp} className={styles.erpSmallLogo} />
        } else if (erp === erpIdentifiers.swedishSIE) {
            logo = <Icon name="calculator" size="large" color='blue' data-erp={erp} className={styles.erpSmallLogo} />
        }

        const trigger = <div className={styles.erpWrapper}>
            { logo }
        </div>;
        return <Popup trigger={trigger} content={erpSystem.name} position='top center' />;
    }

    renderClientIcon = (icon, cid) => {
        const ikon = <Icon
            size='large'
            name={icon}
            onClick={() => this.gotoCustomerFrontpage(cid)}
        />;

        return ikon;
    }

    
    // status tabelcelle, inkl. progressbar
    renderStatusCol = (serviceType, status) => {
        const st = statusesMap[status];
        if (!st) return <span>-</span>;
        const { displayText, step } = st;

        // TODO: this is pretty hacky
        // the set of statuses should come from the product
        const isYearlyReport = serviceType === 'Årsrapport';

        if (!isYearlyReport && st === statusesMap.done) {
            return null;
        } 

        const lastStepInFlow = isYearlyReport ? 5 : 2;
        const productStatuses = isYearlyReport ? [
            i18n.progressInTaxYearStarted,
            i18n.progressInTaxYearErpDataFetched,
            i18n.progressInTaxYearToBeSigned,
            i18n.progressInTaxYearSigned,
            i18n.progressInTaxYearReported,
        ] : [
            i18n.progressInTaxYearStarted,
            i18n.progressInTaxYearReported,
        ];
        let icons = [1, 2, 3, 4, 5].map((s, i) => {
            let result, icon;
            if(s <= lastStepInFlow) {
                if(s <= step) {
                    const dotShouldBeChecked = s === step && s === lastStepInFlow;
                    icon = <Icon key={s} className={styles.statusOn} name={`${dotShouldBeChecked ? 'check' : ''} circle`} fitted />;
                } else {
                    icon = <Icon key={s} disabled fitted name='circle outline' />;
                }
                result = <Popup content={productStatuses[i]} trigger={icon} size='mini' position='top center' />
            } else {
                result = <Icon key={s} disabled fitted name='circle outline' className={styles.transparent} />;
            }
            return result;
        });

        let displayNameCN = styles.statusDisplayName;
        return <div className={styles.nowrap}>
            {icons}
            <span className={displayNameCN}>{displayText}</span>
            &nbsp;
            {/*
            Vis substatus for indberetninger (skat, erst)
            TODO hent fra product-engine (client-info)
            hideIconOnSmallDevices
            <Icon className={styles.achievement} name="trophy" disabled={step===5 ? false : true} data-step={`${step}`} />
            <Icon className={styles.achievement} name="check circle outline" disabled={step===5 ? false : true} data-step={`${step}`} /> */}
        </div>;
    }

    renderAccountSuspended = () => {
        return <AccountSuspendedMessage />;
    };

    renderCreateClientModal = () => {
        const { activeModal } = this.state;

        if (!activeModal) {
            return null;
        }

        const clientType = this.CLIENT_TYPES.find(clientType => clientType.type === activeModal);
        if (!clientType) {
            return null;
        }

        return (
            <CreateClientModal
                onClientCreated={this.refetchClientList}
                close={() => this.setState({ activeModal: null })}
                isPrivate={clientType.isPrivate}
                header={clientType.header}
                icon={clientType.icon}
            />
        );
    };

    renderClientSettingsModal = () => {
        const { pickedClient } = this.state;

        if (!pickedClient) {
            return;
        }

        const close = () => this.setState({ pickedClient: null });

        return (
            <ClientSettings
                client={pickedClient}
                onDelete={this.onClientDeleted}
                onUpdate={this.refetchClientList}
                close={close}
            />
        );
    };

    renderContent = () => {
        const { error, loading } = this.state;

        if (loading) {
            return <Segment basic textAlign='center'>
                <Loader inline active size='huge' />
            </Segment>;
        };

        let content;
        if (error) {
            /* i18n */
            content = <Message
                content= {i18n.errorGettingListOfClients}
                icon='warning circle'
                warning
            />;
        } else {
            const clients = this.formatClientsData();
            content = <>
                {this.renderAccountSuspended()}
                {this.renderTable(clients)}
            </>;
        }

        return (
            <Container className={styles.container}>
                <Grid columns='equal' verticalAlign='middle'>
                    <Grid.Column>
                        <Header
                            as='h2'
                            content='Klientliste'
                            subheader='Se dine klienters skatteår'
                        />
                    </Grid.Column>
                    {this.countClients() > 0 && (
                        <Grid.Column textAlign='right'>
                            {this.renderCreateClientButtons()}                        
                        </Grid.Column>
                    )}
                </Grid>
                <Segment padded>
                    {content}
                </Segment>
            </Container>
        );
    };

    render () {
        return (
            <>
                {this.renderContent()}
                {this.renderCreateClientModal()}
                {this.renderClientSettingsModal()}
            </>
        );
    }
}

export default withRouter(
    withRootUserData(
        withPersistedState(AccountantOverview, 'filterClientsForm', {
            pickedStatuses: [],
            pickedTaxYears: [],
            onlyDisplayAssignedClients: false,
        }),
    ),
);