import React from 'react';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react';
import { patchLoggedInUser } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import useUser from 'util/useUser';
import UploadLogo from 'design/molecules/UploadLogo';

const LogoSettings = () => {
    const user = useUser();

   const positionOptions = [
        { key: 'left', text: 'Venstre', value: 'left' },
        { key: 'center', text: 'Midten', value: 'center' },
        { key: 'right', text: 'Højre', value: 'right' },
    ];

    return (
        <>
            <UploadLogo />
            <br />

            <strong>Standard logoposition på årsrapport:</strong>
            <Dropdown
                fluid
                selection
                placeholder='Vælg standard logoposition'
                style={{ marginTop: '0.5em' }}
                options={positionOptions}
                defaultValue={user.logoPosition}
                onChange={(_, { value }) => {
                    patchLoggedInUser({ logoPosition: value })
                        .then(() => refreshTokens())
                        .then(() => toast.success('Logoposition opdateret'))
                        .catch(() => toast.error('Logoposition blev ikke opdateret...'));
                }}
            />
        </>
    );
};

export default LogoSettings;