import { HTTPClient } from 'network/fetch';
import { withLocalCacheAsync } from 'util/localCache';

const client = new HTTPClient({ prefix: 'taxonomy-parser' });

export const listTaxonomyTags = withLocalCacheAsync((taxonomyID, language = 'default') => {
    return client.getResource(`/api/taxonomies/${taxonomyID}/tags?language=${language}`);
});

export const getTaxonomyPresentationOrder = withLocalCacheAsync(taxonomyID => {
    return client.getResource(`/api/taxonomies/${taxonomyID}/presentation-order`);
});