import get from 'lodash.get';
import set from 'lodash.set';
import sortBy from 'lodash.sortby';
import evaluateFormula from '@digital-revisor/node-formula-interpreter/lib/evaluate';
import findFormulaReferences from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';
import interpret from '@digital-revisor/node-formula-interpreter/lib';
import { toast } from 'react-toastify';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Confirm, Dropdown, Form, Grid, Icon, Input, Loader, Modal, Popup, Segment, Header } from 'semantic-ui-react';
import * as productEngine from 'http/productEngine';
import { deleteFile, uploadFile } from 'http/file-storage';
import { unixSeconds } from 'util/format/DateTime';
import { isAdmin } from 'util/userMethods';
import crc32 from 'util/crc32';
import withUserData from 'util/withUserData';
import deepCopy from 'util/deepCopyObject';
import FileUploadModal from 'design/atoms/FileUploadModal';
import DatePicker from 'design/atoms/DatePicker';
import NumberInput from 'design/atoms/NumberInput';
import ColoredText from 'design/atoms/ColoredText';
import FieldGroup from 'design/atoms/FieldGroup';
import YesNo from 'design/atoms/YesNo';
import ToolTip from 'design/atoms/Tooltip';
import Tooltip from 'design/atoms/Tooltip';
import styles from './Resource.module.scss';

const isPropertyVisible = (property, resourcePropertyDefinitions, resourcePropertyValues) => {
    const visibilityFormula = property.visibilityFormula;

    if (!visibilityFormula) {
        return true;
    }

    const tree = interpret(visibilityFormula);
    const referencedProperties = findFormulaReferences(tree);
    
    const referenceMap = {};
    referencedProperties.forEach(refProp => {
        const tagValue = resourcePropertyValues[refProp];
        if (!tagValue) return;

        const referencedDefinition = resourcePropertyDefinitions.find(prop => prop.tag === refProp)

        let tagValueAsNumber;

        switch (referencedDefinition.dataType) {
            case 'Number':
            case 'Boolean':
            case 'Date':
                tagValueAsNumber = Number(tagValue);
                break;
            case 'String':
            case 'EnumString':
                tagValueAsNumber = crc32(tagValue);
                break;
            default:
                tagValueAsNumber = 0;
        }

        referenceMap[refProp] = tagValueAsNumber;
    });
    
    const shouldBeVisible = evaluateFormula(tree, referenceMap);

    return shouldBeVisible !== 0;
};

export const createCleanResource = () => ({
    slug: '',
    values: {},
    files: {},
    deletedFiles: [],
    yearsToSkip: [],
});

const CreateResourceModal = ({
    open,
    onClose,
    onDelete,
    errors,
    working,
    resourceTemplate,
    resource,
    onResourceChange,
    onSkipResourceChange,
    onFileAdded,
    onFileDeleted,
    onCreate,
    user,
    taxYear,
    productType,
    shouldSkip,
    isLocked,
    hasPaid,
}) => {
    const isDisabled = resource?.disabled || isLocked;
    const isCreating = !resource?.slug;

    const updateResourceProperty = (propertyPath, value) => {
        onResourceChange(propertyPath, value);
    };

    const renderPropertyInputField = ({ dataType, tag, options, hasFileupload }) => {
        const { values, files } = resource;
        const tagValue = get(values, tag);
        const tagFiles = get(files, tag, []);

        // prepare file upload modal, if specified in resource template
        let fileAttachmentWidget;
        if (hasFileupload) {
            fileAttachmentWidget = (
                <FileUploadModal
                    listFiles={() => tagFiles}
                    uploadFile={file => onFileAdded(tag, file)}
                    deleteFile={(_, fileIndex) => {
                        onFileDeleted(tag, fileIndex)
                    }}
                />
            );
        }

        const inputIsDisabled = isDisabled || working;

        switch (dataType) {
            case 'Number':
                return <NumberInput 
                    disabled={inputIsDisabled}
                    onChange={value => updateResourceProperty(tag, value)}
                    value={tagValue}
                    leftAttachment={fileAttachmentWidget}
                />;
            case 'Date':
                return <DatePicker
                    disabled={inputIsDisabled} 
                    onChange={value => updateResourceProperty(tag, unixSeconds(value))}
                    value={tagValue && tagValue * 1000}                   
                />;
            case 'String':
                return <Input
                    disabled={inputIsDisabled}
                    onChange={(_, { value }) => updateResourceProperty(tag, value)}
                    defaultValue={tagValue}
                />;
            case 'Boolean':
                return <YesNo
                    disabled={inputIsDisabled}
                    onChange={value => updateResourceProperty(tag, value)}
                    value={tagValue}
                />;
            case 'EnumString':
                return <Dropdown
                    disabled={inputIsDisabled}
                    selection
                    onChange={(_, { value }) => updateResourceProperty(tag, value)}
                    defaultValue={tagValue}
                    options={options.map(option => ({
                        text: option.label,
                        value: option.value,
                    }))}
                />
            default:
                return null;
        }
    };

    const renderPropertyFormComponent = property => {
        const propertyIsVisible = isPropertyVisible(property, resourceTemplate.properties, resource.values);
        if (!propertyIsVisible) {
            return null;
        }

        const fieldError = errors[property.tag];

        const tooltip = (
            property.tooltip && 
            <ToolTip data={property.tooltip} />
        );

        const video = (
            property.youtubeID &&
            <ToolTip youtubeID={property.youtubeID} videoLocked={!hasPaid} />
        );

        return (
            <Form.Field error={!!fieldError}>
                <label >{property.name}{tooltip}{video}</label>
                {renderPropertyInputField(property)}
                { fieldError && <ColoredText color='red' content={fieldError} /> }
            </Form.Field>
        );
    };

    const renderLoadingIcon = () => (
        <Icon name='spinner' loading />
    );

    let createButtonText;
    let createButtonIcon;
    let modalHeader;

    if (isCreating) {
        createButtonText = working ? 'Opretter' : 'Opret';
        createButtonIcon = working ? renderLoadingIcon() : 'check';
        modalHeader = resourceTemplate.texts.create;
    } else {
        createButtonText = working ? 'Gemmer' : 'Gem';
        createButtonIcon = working ? renderLoadingIcon() : 'save';
        modalHeader = resourceTemplate.texts.edit;
    }

    if (!resource) {
        return null;
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Modal.Header>
                <Icon name={resourceTemplate.semanticIcon} />
                {modalHeader}
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        {resourceTemplate.properties.map(renderPropertyFormComponent)}

                        {/* Render delete button for admins only */}
                        {
                            isAdmin(user) && !isCreating &&
                            <Form.Field>
                                <ColoredText
                                    link
                                    content='Slet'
                                    icon='trash'
                                    color='red'
                                    iconPosition='left'
                                    onClick={onDelete}
                                />
                            </Form.Field>
                        }
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {
                    !isDisabled && !isCreating &&
                    <span className={styles.skipContainer}>
                        <Checkbox
                            toggle
                            label={`Er aktiv i ${taxYear}`}
                            checked={!shouldSkip}
                            onChange={() => {
                                onSkipResourceChange(!shouldSkip);
                            }}
                        />
                        <ToolTip
                            position='top center'
                            data={`Slå fra hvis boligen ikke er relevant for din ${productType.toLowerCase()} for ${taxYear}`}
                        />
                    </span>
                }
                <Button
                    color="black"
                    onClick={onClose}
                    disabled={working}
                    labelPosition='right'
                    icon='x'
                    content='Annuller'
                />
                <Button
                    primary
                    onClick={onCreate}
                    disabled={isDisabled || working}
                    labelPosition='right'
                    icon={createButtonIcon}
                    content={createButtonText}
                />
            </Modal.Actions>
        </Modal>
    );    
};

const ResourceCard = ({ name, icon, onEdit, statusText, statusIcon, statusTooltip, statusColor }) => {
    return (
        <Segment className={styles.resourceCard} onClick={onEdit}>
            <Grid columns={2} textAlign='middle'>
                <Grid.Column>
                    <ColoredText
                        content={<b>{name}</b>}
                        icon={icon}
                        iconPosition='left'
                        bold
                    />
                </Grid.Column>
                <Grid.Column textAlign='right' verticalAlign='middle'>
                    {
                        statusText &&
                        <Popup
                            inverted
                            position='top center'
                            content={statusTooltip}
                            trigger={
                                <span>
                                    <ColoredText color={statusColor}>
                                        {statusText}
                                        &nbsp;
                                        <Icon name={statusIcon} />
                                    </ColoredText>
                                </span>
                            }
                        />
                    }
                </Grid.Column>
            </Grid>
        </Segment>
    );
};


const Resource = (props) => {
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [resourceTemplate, setResourceTemplate] = useState(null);
    const [userResources, setUserResources] = useState([]);
    const [resourceBeingCreated, setResourceBeingCreated] = useState(createCleanResource());
    const [resourceBeingDeleted, setResourceBeingDeleted] = useState(null);
    const [creationErrors, setCreationErrors] = useState({});

    const getResources = useCallback(() => {
        return productEngine.getResources(props.options.resourceSlug, props.productMetadata.id);
    }, [props.options.resourceSlug, props.productMetadata.id]);

    // component service methods
    const fetchUserResources = useCallback(async () => {
        const userResources = await getResources();
        setUserResources(userResources);
    }, [getResources]);

    const fetchResourceTemplates = useCallback(async () => {
        const resourceTemplate = await productEngine.getResourceTemplate(props.options.resourceSlug);
        setResourceTemplate(resourceTemplate);
    }, [props.options.resourceSlug]);

    const reloadState = useCallback(async () => {
        await Promise.all([
            productEngine.runAction(props.productId, props.productLabel, props.supplier),
            fetchUserResources(),
        ]);
        await props.reloadProductData();
    }, [fetchUserResources, props]);

    const doDeleteResource = useCallback(async ({ slug, name }) => {
        setWorking(true);
        await productEngine.deleteUserResource(slug);
        await reloadState();
        setWorking(false);

        // confirm deletion w/ toast
        toast.success(
            <span>
                <b>{name}</b> blev fjernet
            </span>,
        );

        setResourceBeingDeleted(null);
        setResourceBeingCreated(null);
    }, [reloadState]);

    const createNewResource = () => {
        setResourceBeingCreated(createCleanResource());
        setCreating(true);
    };

    // called on mount
    useEffect(() => {
        const onMount = async () => {
            await Promise.all([
                fetchResourceTemplates(),
                fetchUserResources(),
            ]);

            setLoading(false);
        };

        onMount();
    }, [fetchUserResources, fetchResourceTemplates]);

    const updateCurrentResourceProperty = (propertyPath, value) => {
        setCreationErrors({});
        const updatedResource = { ...resourceBeingCreated };
        set(updatedResource.values, propertyPath, value);
        setResourceBeingCreated(updatedResource);
    };

    const updateCurrentResourceSkipRule = (shouldSkip) => {
        const newRules = [...resourceBeingCreated.yearsToSkip || []];
        const taxYear = Number(props.productMetadata.year);
        const isAdvanceStatement = props.productMetadata.isAdvanceStatement;

        if (shouldSkip) {
            newRules.push({
                taxYear: taxYear,
                advanceStatement: isAdvanceStatement,
            });
        } else {
            const ruleToSplice = newRules.findIndex(rule => {
                return rule.taxYear === taxYear && rule.advanceStatement === isAdvanceStatement;
            });
            if (ruleToSplice !== -1) {
                newRules.splice(ruleToSplice, 1);
            }
        }
        const updatedResource = {
            ...resourceBeingCreated,
            yearsToSkip: newRules,
        };
        setResourceBeingCreated(updatedResource);
    };

    const addResourceFile = (tag, file) => {
        const updatedResource = { ...resourceBeingCreated };
        const propertyPath = `files.${tag}`;

        const currentFiles = get(updatedResource, propertyPath, []);
        currentFiles.push(file);
        set(updatedResource, propertyPath, currentFiles);

        setResourceBeingCreated(updatedResource);
    };

    const deleteResourceFile = (tag, fileIndex) => {
        const updatedResource = { ...resourceBeingCreated };
        const [deletedFile] = updatedResource.files[tag].splice(fileIndex, 1);
        updatedResource.deletedFiles.push(deletedFile);
        setResourceBeingCreated(updatedResource);
    };

    const shouldSkipResource = (resource) => {
        const validSkipRule = resource?.yearsToSkip?.find(({ taxYear, advanceStatement }) => {
            if (taxYear !== Number(props.productMetadata.year)) {
                return false;
            }

            if (advanceStatement !== props.productMetadata.isAdvanceStatement) {
                return false;
            }

            return true;
        });
        return !!validSkipRule;
    };

    const getResourceStatus = (resource) => {
        const { productMetadata } = props;
        const taxYear = productMetadata.year;
        const kind = productMetadata.kind.toLowerCase();

        if (resource.disabled) {
            return {
                text: resourceTemplate.texts.disabled,
                icon: 'tag',
                color: 'black',
                tooltip: `Du har erklæret boligen solgt i skatteår ${resource.taxYearDisabled}`,
                relevant: false,
            };
        }

        if (shouldSkipResource(resource)) {
            return {
                text: 'Ej aktiv',
                icon: 'pause circle',
                color: 'yellow',
                tooltip: `Du har valgt at boligen ikke er relevant for din ${kind} i ${taxYear}`,
                relevant: false,
            };
        }

        return {
            text: 'Aktiv',
            icon: 'check circle',
            color: 'green',
            tooltip: `Boligen er aktiv i ${taxYear} og vil indgå i beregningerne for din ${kind}`,
            relevant: true,
        };
    };

    const doUpsertResource = async () => {
        // remove any previous errors
        setCreationErrors({});

        const validationErrors = {};

        // validate that all required resources are filled out
        // and remove values of hidden fields
        for (const property of resourceTemplate.properties) {
            const { required, tag } = property;

            const propertyIsVisible = isPropertyVisible(property, resourceTemplate.properties, resourceBeingCreated.values);
            if (!propertyIsVisible) {
                delete resourceBeingCreated.values[tag];
            } else if (required && resourceBeingCreated.values[tag] === undefined) {
                validationErrors[tag] = 'Feltet er påkrævet';
            }

            // Validate that no names (boligens_navn) are duplicate    
            if (!resourceBeingCreated.slug) {
                for (let i = 0; i < userResources.length; i++){
                    if (resourceBeingCreated.values[tag] === userResources[i].values.boligens_navn) {
                        validationErrors[tag] = 'Hver bolig skal have et unikt navn'
                    }              
                }
            }
        }
        
        // exit here, if any validation errors
        if (Object.keys(validationErrors).length > 0) {
            setCreationErrors(validationErrors);
            return;
        }

        const fileJobs = [];

        // find all new files && prepare upload handler
        for (let files of Object.values(resourceBeingCreated.files || [])) {
            for (let tagFileIndex in files) {
                const file = files[tagFileIndex];
                if (!file.id) {
                    fileJobs.push(async () => {
                        const fileID = await uploadFile(file);
                        files[tagFileIndex] = {
                            id: fileID,
                            name: file.name,
                            size: file.size,
                            contentType: file.type,
                        };
                    });
                }
            }
        }

        // prepare removal handlers for deleted files
        const filesToDelete = resourceBeingCreated.deletedFiles.filter(file => {
            return !!file.id;
        });
        
        fileJobs.push(...filesToDelete.map(file => {
            return () => deleteFile(file.id);
        }));

        setWorking(true);

        // remove deleted files && upload new ones
        await Promise.all(fileJobs.map(job => job()));

        // do upsert
        await productEngine.upsertResource(resourceTemplate.slug, resourceBeingCreated, props.productMetadata.id);

        // refresh UI && run calculations
        await reloadState();

        // display success toast
        const resourceName = resourceBeingCreated.values[resourceTemplate.titleProperty];
        toast.success(
            <span>
                <b>{resourceName}</b> blev {resourceBeingCreated.slug ? 'opdateret' : 'oprettet'}!
            </span>,
        );

        setResourceBeingCreated(createCleanResource());
        setWorking(false);
        setCreating(false);
    };

    const renderContent = () => {
        // render loading spinner
        const newDesign = true;
        if (loading) {
            return (
                <Loader
                    inline='centered'
                    size='huge'
                    active
                />
            );
        }

        // prepare video tooltip
        const videoTooltip = (
            props.youtubeVideo &&
            <Tooltip
                trigger={
                    <ColoredText
                        style={{
                            marginTop: '16px',
                            display: 'block',
                        }}
                        icon='play circle'
                        iconPosition='left'
                        color='blue'
                        content={props.youtubeVideo.title}
                        link
                        underlined={false}
                    />
                }
                youtubeVideo={props.youtubeVideo}
                videoLocked={!props.hasPayed}
            />
        );

        // if no resources yet, render a special UI for first time resource creation
        if (userResources.length === 0) {
            return (
                <>
                <Segment style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems:'center'
                }}>
                    <Icon size='huge' name={resourceTemplate.semanticIcon} />
                    <Header as='h3' style={{ margin:'1rem 0' }}>{resourceTemplate.texts.createFirst}</Header>
                    <Button fluid icon='plus' content={resourceTemplate.texts.create} onClick={createNewResource} primary />
                </Segment>
                </>
            );
        }

        const sortedResources = sortBy(userResources, [
            r => r.disabled,
            r => shouldSkipResource(r),
            r => r.values[resourceTemplate.titleProperty],
        ]);

        // display a list of created resources & allow creation of more
        const mainContent = (
            <>
            <div>
                {sortedResources.map(userResource => {
                    const name = userResource.values[resourceTemplate.titleProperty];
                    const icon = resourceTemplate.semanticIcon;
                    const status = getResourceStatus(userResource);

                    return (
                        <ResourceCard
                            name={name}
                            icon={icon}
                            statusText={status.text}
                            statusIcon={status.icon}
                            statusTooltip={status.tooltip}
                            statusColor={status.color}
                            onEdit={() => {
                                setResourceBeingCreated(deepCopy({
                                    ...userResource,

                                    // local list for keeping track of deleted resource files
                                    deletedFiles: [],
                                }));
                                setCreating(true);
                            } }
                            onDelete={() => setResourceBeingDeleted({
                                slug: userResource.slug,
                                name,
                            })} 
                            
                            />
                    );
                })}

                    <Button
                        onClick={createNewResource}
                        className={styles.createContainer}
                        content={resourceTemplate.texts.create}
                        disabled={props.isLocked}
                        icon='plus'
                        fluid
                    />

                                {
                        videoTooltip &&
                        <div style={{ textAlign: 'center' }}>
                            {videoTooltip}
                        </div>
                    }
            </div>
                </>
        );

        if(!newDesign){
            return (
                <>
                    {mainContent}
                </>
            )
        }

        return (
            <FieldGroup
                title={resourceTemplate.texts.mainTitle}
                children={mainContent}
            />
        );
    };

    return (
        <>
            {renderContent()}
            {
                !loading && (
                    <CreateResourceModal
                        resourceTemplate={resourceTemplate}
                        resource={resourceBeingCreated}
                        errors={creationErrors}
                        working={working}
                        onResourceChange={updateCurrentResourceProperty}
                        onSkipResourceChange={updateCurrentResourceSkipRule}
                        onFileAdded={addResourceFile}
                        onFileDeleted={deleteResourceFile}
                        onCreate={doUpsertResource}
                        open={creating}
                        onDelete={() => setResourceBeingDeleted({
                            slug: resourceBeingCreated.slug,
                            name: resourceBeingCreated.values[resourceTemplate.titleProperty],
                        })}
                        onClose={() => setCreating(false)}
                        user={props.userData}
                        taxYear={props.productMetadata.year}
                        productType={props.productMetadata.kind}
                        isLocked={props.isLocked}
                        hasPaid={props.hasPayed}
                        shouldSkip={shouldSkipResource(resourceBeingCreated)}
                    />
                )
            }
            {
                !!resourceBeingDeleted && (
                    <Confirm
                        open
                        header='Bekræft sletning'
                        content={`Er du sikker på at du vil slette ${resourceBeingDeleted.name}?`}
                        confirmButton={
                            <Button
                                content={working ? 'Sletter' : 'Slet'}
                                disabled={working}
                                icon={
                                    <Icon
                                        name={working ? 'spinner' : 'trash alternate'}
                                        loading={working}
                                    />
                                }
                            />
                        }
                        cancelButton={
                            <Button
                                content='Annuller'
                                disabled={working}
                            />
                        }
                        onConfirm={() => doDeleteResource(resourceBeingDeleted)}
                        onCancel={() => setResourceBeingDeleted(null)}
                    />
                )
            }
        </>
    );
};

export default withUserData(Resource);