import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Icon, List, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { getReportingEntities, deleteCurrentLogin } from 'http/accounts';
import { entityRoleLevels } from 'util/TokenUser';
import { clearAllTokens } from 'util/token';
import { useRootUser } from 'util/useUser';
import { toast } from 'react-toastify';
import { stopImpersonation } from 'util/impersonation';

const LoginSettings = () => {
    const [confirming, setConfirming] = useState(false);
    const [working, setWorking] = useState(false);
    const [isSure, setIsSure] = useState(false);
    const [ownedReportingEntities, setOwnedReportingEntities] = useState(null);
    const history = useHistory();
    const user = useRootUser();

    const doClose = () => setConfirming(false);

    const doDelete = async () => {
        setWorking(true);
        try {
            await deleteCurrentLogin();
            clearAllTokens();
            stopImpersonation();
            history.push(`/bye?segment=${user.login.segment}`);
        } catch {
            toast.error('Der opstod en fejl. Din konto blev ikke slettet.');
        } finally {
            setWorking(false);
        }
    };

    useEffect(() => {
        if (!confirming) {
            setIsSure(false);
            return;
        }

        // skip fetching reporting entities, if already done
        if (ownedReportingEntities) {
            return;
        }

        const doFetchReportingEntitites = async () => {
            setWorking(true);

            // fetch all assoc. reporting entitites
            const reportingEntities = await getReportingEntities();

            // find the ones where the logged in user is owner
            setOwnedReportingEntities(reportingEntities.filter(entity => {
                return entity.role.level === entityRoleLevels.owner;
            }));

            setWorking(false);
        };

        doFetchReportingEntitites();
    }, [confirming, ownedReportingEntities]);

    const renderLoading = () => {
        return (
            <>
                <Icon name='spinner' loading />
                Øjeblik...
            </>
        );
    };

    const renderDeleteMessage = () => {
        return (
            <>
                <p>
                    <b>OBS: </b>
                    Når du sletter din konto vil regnskabsdata, filer og indtastninger forbundet med kontoen blive slettet.
                    Efter sletning vil det ikke være muligt at tilgå førnævnte data på Digital Revisor.
                </p>
                {
                    user.isSegmentErhverv() &&
                    ownedReportingEntities?.length > 0 &&
                    <p>
                        Følgende virksomheder slettes:
                        <br />
                        <List bulleted>
                            {ownedReportingEntities.map(({ displayName, roles }) => {
                                const deletingClientsMessage = (
                                    roles?.includes('revisor') &&
                                    <span> samt alle tilknyttede klienter og regnskaber</span>
                                );

                                return (
                                    <List.Item key={displayName}><b>{displayName}</b>{deletingClientsMessage}</List.Item>
                                );
                            })}
                        </List>
                    </p>
                }
                <Divider clearing />
                <Checkbox
                    label='Jeg har læst ovenstående og ønsker at lukke min konto'
                    defaultChecked={isSure}
                    onChange={(_, { checked }) => setIsSure(checked)}
                />
            </>
        );
    };

    // @todo: Implement deletion of login/entites
    return (
        <>
            <Button color='red' basic content='Slet konto' onClick={() => setConfirming(true)} />
            <Modal open={confirming} onClose={doClose}>
                <Modal.Header>
                    <Icon name='warning sign' color='red' />
                    Slet konto
                </Modal.Header>
                <Modal.Content>
                    {
                        working ?
                        renderLoading() :
                        renderDeleteMessage()
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Annuller'
                        icon='x'
                        onClick={doClose}
                    />
                    <Button
                        content='Slet konto'
                        color='red'
                        disabled={working || !isSure}
                        onClick={doDelete}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default LoginSettings;
