import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import useUser from 'util/useUser';
import ColoredText from '../ColoredText';

const PageHeader = ({ loading, content, rightContent }) => {
    const user = useUser();
    const location = useLocation();

    if (!user.id) {
        return null;
    }

    let impersonationPrefix = '';
    if (user.impersonation && !user.impersonatingOwnClient) {
        impersonationPrefix = 'Impersonerer: ';
    }

    const slash = (
        <span
            style={{ opacity: 0.50 }}
            children={<>&nbsp;/&nbsp;</>}
        />
    );

    return (
        <div style={{ background: 'white', padding: '10px 0', borderBottom: '1px solid #d3d6db' }}>
            <Container>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1, display: 'flex', fontWeight: 'bold' }}>
                        <ColoredText
                            content={`${impersonationPrefix}${user.displayName}`}
                            link={location.pathname === '/' ? undefined : '/'}
                            underlined={false}
                        />
                        {(loading || content) && slash}{loading ? <Loader inline active size='tiny' /> : content}
                    </div>
                    {
                        rightContent && <div>
                            {rightContent}
                        </div>
                    }
                </div>
            </Container>
        </div>
    );
};

export default PageHeader;