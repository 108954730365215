import React, { useEffect, useState } from 'react';
import { Grid, Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { SHOW_CHAT_HISTORY } from 'flags';
import { Media } from 'design/atoms/Media';
import PageHeader from 'design/atoms/PageHeader';
import ProductPage from 'design/molecules/ProductPage';
import useUser, { useRootUser } from 'util/useUser';
import IconHeader from './IconHeader';

// subpages; entity
import EntityInformation from './EntityInformation';
import EntityIAM from './EntityIAM';
import Subscriptions from './Subscriptions';
import Card from './Card';
import Invoices from './Invoices';
import InvoiceOverview from './InvoiceOverview';
import InvoiceMethod from './InvoiceMethod';
import CreditTransactions from './CreditTransactions';

// subpages; login
import LoginInformation from './LoginInformation';
import LoginChangePassword from './LoginChangePassword';
import LoginEntities from './LoginEntities';
import LoginCreateEntity from './LoginCreateEntity';
import LoginSettings from './LoginSettings';
import LogoSettings from './LogoSettings';
import { stopImpersonation } from 'util/impersonation';

// subpages; privat
import PrivateAccountData from './PrivateAccountData';
import EntityChatHistory from './EntityChatHistory';
import { getCreditsBox } from 'http/credits';

// menu items connected to the reporting entity itself
const getEntityMenuItems = async user => {
    let showCreditCardMenuOptions = true;
    if (user.isAccountant()) {
        const creditsBox = await getCreditsBox();
        showCreditCardMenuOptions = creditsBox.overdrawAllowed;
    }

    return [
        {
            title: 'Stamdata',
            icon: user.getSegmentIcon(),
            Component: EntityInformation,
            isVisible: () => user.isSegmentErhverv(),
        },
        {
            title: 'Adgange',
            icon: 'users',
            Component: EntityIAM,
            isVisible: () => (user.isSegmentErhverv() || user.isSegmentAkademi()) && user.isEntityAdmin(),
        },
        {
            title: 'Firmalogo',
            icon: 'image',
            Component: LogoSettings,
            isVisible: () => user.isAccountant() && user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Faktureringsmetode',
            icon: 'dollar',
            Component: InvoiceMethod,
            isVisible: () => showCreditCardMenuOptions && user.isAccountant() && user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Kortoplysninger',
            icon: 'credit card',
            Component: Card,
            isVisible: () => showCreditCardMenuOptions && user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Abonnementer',
            icon: 'redo',
            Component: Subscriptions,
            isVisible: () => !user.isAccountant() && user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Fakturaer',
            icon: 'file alternate',
            Component: Invoices,
            isVisible: () => user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Fakturaudkast',
            icon: 'file alternate',
            Component: InvoiceOverview,
            isVisible: () => showCreditCardMenuOptions && user.isAccountant() && user.isEntityAdmin() && !user.isSegmentAkademi(),
        },
        {
            title: 'Chathistorik',
            icon: 'chat',
            Component: EntityChatHistory,
            isVisible: () => SHOW_CHAT_HISTORY() && user.isEntityAdmin(),
        },
        {
            title: 'Credittransaktioner',
            icon: 'exchange',
            Component: CreditTransactions,
            isVisible: () => user.isAccountant() && user.isEntityAdmin(),
        },
    ];
};

// menu items connected to the account of the user
// (login email, phone number, etc.)
const getAccountMenuItems = user => ([
    {
        title: 'Kontodata',
        icon: 'address card',
        Component: LoginInformation,
        isVisible: () => user.isSegmentErhverv(),
    },
    {
        title: 'Skift kodeord',
        icon: 'lock',
        Component: LoginChangePassword,
    },
    {
        title: 'Virksomheder',
        icon: user.getSegmentIcon(),
        Component: LoginEntities,
        isVisible: () => user.isSegmentErhverv(),
    },
    {
        title: 'Opret virksomhed',
        icon: 'signup',
        Component: LoginCreateEntity,
        isVisible: () => user.isSegmentErhverv(),
    },
    {
        title: 'Kontoindstillinger',
        icon: 'cogs',
        Component: LoginSettings,
    },
]);

const toPageName = title => {
    if (!title) {
        return '';
    }

    return (
        title
        .toLowerCase()
        .replace('æ', 'ae')
        .replace('ø', 'oe')
        .replace('å', 'aa')
        .replace(' ', '_')
    );
};

const SideMenu = ({ menuItems, loading }) => {
    const history = useHistory();
    const location = useLocation();
    const [menuPath, subPath] = location.pathname.split('/').filter(p => p);
    const root = `/${[menuPath, subPath].join('/')}`;

    if (loading) {
        return (
            <Loader inline='centered' active />
        );
    }

    if (!menuItems) {
        return null;
    }

    const createPath = title => {
        return `${root}/${toPageName(title)}`;
    };

    const isPageActive = title => location.pathname.startsWith(createPath(title));

    const activeItem = menuItems.find(item => isPageActive(item.title));
    const currentIcon = activeItem?.icon;
    const currentTitle = activeItem?.title;

    const menu = (
        <Menu vertical pointing secondary fluid>
            {
                menuItems.map(({ title, icon, isVisible }) =>
                    (!isVisible || isVisible()) &&
                    <Menu.Item
                        content={
                            <span>
                                <Icon name={icon} />
                                {title}
                            </span>
                        }
                        active={isPageActive(title)}
                        onClick={() => history.push(createPath(title))}
                    />
                )
            }
        </Menu>
    );

    const content = (
        <Segment basic>
            <IconHeader icon={currentIcon} content={currentTitle} />
            <Switch>
                {menuItems.map(({ title, Component, customProps }) => (
                    <Route
                        path={createPath(title)}
                        render={(props) => <Component {...props} {...customProps}/>}
                    />
                ))}
                <Redirect from={root} to={createPath(menuItems[0]?.title)} />
            </Switch>
        </Segment>
    );

    return (
        <Grid>
            <Media gte='computer'>
                <Grid.Column width={4}>
                    {menu}
                </Grid.Column>
                <Grid.Column width={12}>
                    {content}
                </Grid.Column>
            </Media>
            <Media lt='computer'>
                <Grid.Row>
                    <Grid.Column>
                        {menu}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {content}
                    </Grid.Column>
                </Grid.Row>
            </Media>
        </Grid>    
    );
};

const PrivateProfile = () => {
    const user = useRootUser();
    const [menuItems, setMenuItems] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const possibleItems = await getEntityMenuItems(user);

            setMenuItems(possibleItems);
            setLoading(false);
        })();
    }, [user]);

    return (
        <SideMenu
            loading={loading}
            menuItems={[
                {
                    title: 'Kontodata',
                    icon: 'user',
                    Component: PrivateAccountData,
                    customProps: { isEntityAdmin: user.isEntityAdmin() },
                },
                ...(loading ? [] : menuItems),
                ...getAccountMenuItems(user),
            ]}
        />
    );
};

const EntityProfile = () => {
    const [menuItems, setMenuItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastSeenUid, setLastSeenUid] = useState(null);
    const user = useRootUser();

    useEffect(() => {
        // prevent a re-load on access token change
        if (user.id === lastSeenUid) return;

        (async () => {
            setLoading(true);
            setLastSeenUid(user.id);

            const possibleItems = await getEntityMenuItems(user);

            setMenuItems(possibleItems);
            setLoading(false);
        })();
    }, [user, lastSeenUid]);

    return <SideMenu
        menuItems={menuItems}
        loading={loading}
    />;
};

const LoginProfile = () => {
    const user = useRootUser();

    return (
        <SideMenu
            menuItems={getAccountMenuItems(user)}
        />
    );
};

const Profile = () => {
    const user = useRootUser();
    const appUser = useUser();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!appUser.impersonation) {
            return;
        }
        
        // automatically quit impersonation before accessing menu
        stopImpersonation();
    }, [appUser.impersonation]);

    const getRoutes = () => {
        if (user.isSegmentPrivat() || user.isSegmentAkademi()) {
            // private and academy users only have one menu
            return [
                {
                    icon: user.getSegmentIcon(),
                    title: 'Profil',
                    path: '/indstillinger/profil',
                    component: PrivateProfile,
                },
            ];
        }

        return [
            {
                icon: user.getSegmentIcon(),
                title: user.displayName,
                path: '/indstillinger/virksomhed',
                component: EntityProfile,
            },
            {
                icon: 'address card',
                title: 'Konto',
                path: '/indstillinger/konto',
                component: LoginProfile,
            },
        ];
    };

    const routes = getRoutes();

    const renderErhvervTabs = () => {
        // private and academy users don't need multiple tabs
        if (user.isSegmentPrivat() || user.isSegmentAkademi()) {
            return null;
        }

        return (
            <Menu tabular attached='top'>
                {routes.map(({ icon, title, path }) => (
                    <Menu.Item
                        icon={icon}
                        content={title}
                        active={location.pathname.startsWith(path)}
                        link={!location.pathname.startsWith(path)}
                        onClick={() => history.push(path)}
                    />
                ))}
            </Menu>
        ); 
    };

    return (
        <ProductPage subHeader={<PageHeader content='Indstillinger' icon='cogs' />}>
            {renderErhvervTabs()}
            <Segment attached='bottom' stacked>
                <Switch>
                    {routes.map(({ path, component }) => (
                        <Route
                            path={path}
                            component={component}
                        />
                    ))}
                    <Redirect from='/indstillinger' to={routes[0].path} />
                </Switch>
            </Segment>
        </ProductPage>
    );
};

export default Profile;