import { getResource, GET, postResource, deleteResource } from 'network/fetch';
import { base64ToBlob } from 'util/files';
import { makeQueryParams } from 'util/QueryParams';
import downloadBlob from 'util/downloadBlob';

const mkLabelParam = label => makeQueryParams({ label });

const prefix = 'product-engine/api';

/**
 * Gets the statuses of all the accountants clients.
 */
export async function getClientInfo () {
    return getResource('product-engine/accountant/client-info', {
        token: 'accessToken',
    });
}

export function getProduct (productID) {
    return getResource(`${prefix}/${productID}`);
}

export function getServiceByID (serviceID) {
    return getResource(`${prefix}/services/${serviceID}`);
}

export function getSectionFields (productID, slug) {
    return getResource(`${prefix}/${productID}/sections/${slug}/fields`);
}

export function hasProductData (productID, label) {
    return getResource(`${prefix}/${productID}/facts/exists${mkLabelParam(label)}`);
}

export async function getFactMap (productID, label) {
    const resp = await GET(`${prefix}/${productID}/facts${mkLabelParam(label)}`);
    return {
        message: resp.getMessage(),
        factMap: resp.getData(),
    };
}

export function updateManyFacts(productID, label, factUpdates) {
    const url = `${prefix}/${productID}/facts${mkLabelParam(label)}`;
    return postResource(url, factUpdates);
}

export function updateFact(productID, label, factID, body) {
    return updateManyFacts(productID, label, [
        {
            id: factID,
            value: body,
        },
    ]);
}

export async function getFactValueByTag(productID, label, tag) {
    const factValue = await getResource(`${prefix}/${productID}/facts/tag/${tag}${mkLabelParam(label)}`);
    return factValue;
}

export function getFact (productID, label, factID) {
    return getResource(`${prefix}/${productID}/facts/${factID}${mkLabelParam(label)}`);
}

export async function printSection (productID, label, slug) {
    const { filename, data } = await postResource(`${prefix}/${productID}/sections/${slug}/print${mkLabelParam(label)}`);
    const asBlob = base64ToBlob(data, 'application/pdf');
    downloadBlob(asBlob, filename);
}

export function getResourceTemplate(slug) {
    return getResource(`${prefix}/resource-templates/${slug}`);
}

export function getResources(templateSlug, productID = '') {
    return getResource(`${prefix}/resources/template/${templateSlug}?productID=${productID}`);
}

export function upsertResource(templateSlug, resource, productID = '') {
    return postResource(`${prefix}/resources/template/${templateSlug}?productID=${productID}`, resource);
}

export function deleteUserResource(slug) {
    return deleteResource(`${prefix}/resources/${slug}`);
}

export function runAction(productID, label, actionID, updateContext = {}) {
    return postResource(`${prefix}/${productID}/actions/${actionID}/run${mkLabelParam(label)}`, updateContext);
}

export async function getProductVideos(productID) {
    return getResource(`${prefix}/${productID}/videos`);
}

export async function getClientsWithAuditorAssistance(activeProduct = '') {
    return getResource(`${prefix}/clients-with-auditor-assistance?product=${activeProduct}`);
}

export async function copyClientAuditorAssistance(fromClient, fromProduct, fromLabel, toProduct, toLabel) {
    return postResource(`${prefix}/${toProduct}/copy-auditor-assistance${mkLabelParam(toLabel)}`, {
        fromClient,
        fromProduct,
        fromLabel,
    });
}

export async function getProductPrimoModelData(productID, label) {
    return getResource(`${prefix}/${productID}/primo-model-data${mkLabelParam(label)}`);
}

export async function runActionRPC(productID, label, actionID, rpcName, args = {}) {
    return postResource(`${prefix}/${productID}/actions/${actionID}/rpc/${rpcName}/run?label=${label}`, args);
}