import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Message } from 'semantic-ui-react';
import ProductPage from 'design/molecules/ProductPage';
import AccountantOverview from 'design/molecules/AccountantOverview';
import PageHeader from 'design/atoms/PageHeader';
import useUser from 'util/useUser';
import getProducts from 'util/getProducts';

const getPathToRelevantProduct = (user, esgIsActive) => {
    if (!user.isCompany()) {
        return '/service/boligudlejning';
    }

    if (user.isSelskab()) {
        return '/service/aarsrapport_b';
    }
    
    if (user.isInteressentskab()) {
        return '/service/aarsrapport_is';
    }

    if (user.isVirksomhed()) {
        return '/service/aarsrapport_a';
    }

    if (user.impersonation && esgIsActive) {
        // any other company type should see the esg product
        // (only accountant clients for now)
        return '/service/esg'; 
    }

    return null; // no relevant product found...
}

const LoadingProductPage = () => <ProductPage subHeader={<PageHeader loading />} loading />;

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [esgIsActive, setEsgIsActive] = useState(false);
    const user = useUser();
    const history = useHistory();

    useEffect(() => {
        getProducts()
            .then(products => {
                const esgIsActive = products.some(product => product.id === 'esg');
                setEsgIsActive(esgIsActive);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <LoadingProductPage />;
    }

    if (user.isAccountant() && !user.impersonation) {
        return (
            <ProductPage
                children={<AccountantOverview user={user} />}
                subHeader={<PageHeader content='Klientliste' icon='users' />}
            />
        );
    }

    const productRedirectPath = getPathToRelevantProduct(user, esgIsActive);
    if (productRedirectPath) {
        history.push(productRedirectPath);
        
        return <LoadingProductPage />;
    }

    return (
        <ProductPage subHeader={<PageHeader />}>
            <Message>
                <Icon name='info circle' />
                Der findes på nuværende ikke tidspunkt ikke nogen produkter
                for virksomheder af typen <strong>{user.businessForm}</strong>.
            </Message>
        </ProductPage>
    );
};

export default Home;